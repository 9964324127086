import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import Overview from "./Overview";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
export const Login = () => {
  const UserNumber = useSelector((state) => state.Name);
  const [Visibility, setVisibility] = useState(false);

  const handleVisibility = () => {
    setVisibility(true);
  };
  const handleVisibilityof = () => {
    setVisibility(false);
  };
  return (
    <>
      <Form className="row m-auto mt-5">
        <div className="col-md-8 m-auto">
          <div className="row shadow   bg-body rounded">
            <div className="col-md-6  ">
              <Row className="mb-3  ">
                <h4 className="col-md-2 m-auto">Login</h4>
              </Row>
              <Row className="mb-3  m-auto">
                <Form.Label>
                  Username <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="user name" />
              </Row>
              {Visibility ? (
                <>
                  <Row
                    className="mb-3  m-auto"
                    style={{ position: "relative" }}
                  >
                    {" "}
                    <Form.Label>
                      Password <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Password " />
                  </Row>
                  <div
                    style={{
                      position: "relative",
                      bottom: "12%",
                      right: "-88%",
                    }}
                  >
                    <VisibilityIcon onClick={handleVisibilityof} />
                  </div>
                </>
              ) : (
                <>
                  <Row className="mb-3  m-auto">
                    {" "}
                    <Form.Label>
                      Password <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control type="password" placeholder="Password " />
                  </Row>
                  <div
                    style={{
                      position: "relative",
                      bottom: "12%",
                      right: "-88%",
                    }}
                  >
                    <VisibilityOffIcon onClick={handleVisibility} />
                  </div>
                </>
              )}

              <Row className="mb-3 mt-2">
                <Button variant="light" href="/Overview">
                  Login
                </Button>{" "}
              </Row>
              <Row className="mb-3 mt-2">
                <Link to="/Signup">
                  <p className="m-2">
                    If your new to srimagicprintz? create account
                  </p>
                </Link>
              </Row>
            </div>
            <img
              alt=""
              className="col-md-6 "
              src="https://img.freepik.com/premium-vector/abstract-vector-business-banner-designs_9583-165.jpg"
            />{" "}
          </div>
        </div>
      </Form>
    </>
  );
};
